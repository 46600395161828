<template>
  <div class="InvalidUsernameorPassword">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./LoginPage" class="page_link">Login Page</router-link> > Invalid Username or Password
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What is "Invalid Username or Password"?</h1>
      <div class="eximg">
        <div>
          <img alt="invalid_username_or_password_en" src="../../assets/invalid_username_or_password_en.png">
        </div>
      </div>
      <h3>Answer</h3>
      <p class="answer">
        This error is caused by mistaking to input UserID or Password.<br />
        Please confirm your UserID or Password.<br />
        If you forget your password, please contact administrator to initialize password,<br />
        or contact the SUZUKI's support helpdesk(<a href="mailto:SSOSupportEX@hhq.suzuki.co.jp">SSOSupportEX@hhq.suzuki.co.jp</a>).
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
       <router-link to="./LoginPage"><img id="back" src="/arrow_back.png" v-on:mouseover="imageBackChange" v-on:mouseleave="imageBackReturn" /></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="./LoginPasswordChanged"><img id="forward" src="/arrow_forward.png" v-on:mouseover="imageForwardChange" v-on:mouseleave="imageForwardReturn" /></router-link>
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

.eximg {
  display: flex;
  flex-direction: row;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
